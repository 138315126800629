"use client";
import React, { createContext, useContext } from "react";
import useFetch from "../fetch";


// TODO: extend fetch type
interface SportsbooksContextType {
  sportsbooks: any | null | undefined;
  topSportsbooks: number[];
  megaMenuSportsbooks: number[];
  featuredSportsbooks: number[];
  isLoading: boolean;
  isError: boolean;
  error: string | null;
}


const SportsbooksContext = createContext<SportsbooksContextType>({
  sportsbooks: undefined as any | null | undefined,
  topSportsbooks: [],
  megaMenuSportsbooks: [],
  featuredSportsbooks: [],
  isLoading: true,
  isError: false,
  error: null as string | null
});

export const useSportsbooks = () => {
  const context = useContext(SportsbooksContext);
  if (!context) {
    throw new Error("useSportsbooks must be used within a SportsbookProvider");
  }
  return context;
};

export function SportsbookProvider({ children }: {children: React.ReactNode}) {
  
  const request = `${process.env.NEXT_PUBLIC_API_URL}/sports/sportsbooks`;
  const { data: sportsbooks, isLoading, isError, error } = useFetch<any | undefined>(undefined, request, []);
  console.log("sportsbooks:", sportsbooks);
  const topSportsbooks = React.useMemo(() => {
    return [];
  }, [sportsbooks]);
  const featuredSportsbooks = React.useMemo(() => {
    return [];
  }, [sportsbooks]);
  const megaMenuSportsbooks = React.useMemo(() => {
    return [];
  }, [sportsbooks]);


  return (
    <SportsbooksContext.Provider value={{ sportsbooks, topSportsbooks, featuredSportsbooks, megaMenuSportsbooks, isLoading, isError, error }}>
      {children}
    </SportsbooksContext.Provider>
  );
};
