"use client";
import React, { useState, useEffect } from 'react';

const CookieConsentDialog = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = document.cookie.split('; ').find(row => row.startsWith('userConsent='));
        if (!consentGiven) {
            setIsVisible(true);
        }
    }, []);

    const setConsentCookie = () => {
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1); 
        document.cookie = `userConsent=true; expires=${expiryDate.toUTCString()}; path=/`;
    };

    const handleAccept = () => {
        setConsentCookie();
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }
    return (
        <div className="fixed bottom-5 left-0 right-0 z-[1000] w-full max-w-[360px] md:max-w-[469px] mx-auto">
            <div className="flex flex-row justify-between items-center gap-2 bg-dark text-white rounded-xl px-4 py-2 md:px-6 md:py-4 bg-[url('/images/consent-dialog-bg.png')] bg-cover bg-center bg-no-repeat shadow-[0px_12px_40px_0px_rgba(0,0,0,0.5)] mx-2">

            <p className="text-xs md:text-sm sm:max-w-none">🍪 <strong>We use cookies</strong> <span className="text-secondary">for functional and analytical purposes.</span></p>
            <button onClick={handleAccept} className="btn btn-xs btn-filled-primary">Accept</button>
            </div>
        </div>
    );
};

export default CookieConsentDialog;
