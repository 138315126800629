"use client"
import React, { createContext, useContext, useState, useEffect } from 'react';

interface CooldownContext {
  isCooldownActive: boolean;
  countdown: number;
  startCooldown: (duration: number) => void;
}

const CooldownContext = createContext<CooldownContext>({
  isCooldownActive: false,
  countdown: 0,
  startCooldown: () => {}
});

export const useCooldown = (): CooldownContext => useContext(CooldownContext);


export const CooldownProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [isCooldownActive, setIsCooldownActive] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);
  const intervalIdRef = React.useRef<NodeJS.Timeout | null>(null);

  function initiateCooldown(duration: number): void {
    const cooldownDuration = duration;
    const expiresAt = new Date(Date.now() + cooldownDuration);
    localStorage.setItem('cooldownExpiresAt', expiresAt.toISOString());
    startCooldown(cooldownDuration);
  };

  const clearCooldownInterval = () => {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const startCooldown = (duration: number): void => {
    clearCooldownInterval();
    setIsCooldownActive(true);
    setCountdown(Math.ceil(duration / 1000));
    intervalIdRef.current = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearCooldownInterval();
          setIsCooldownActive(false);
          setCountdown(0);
          localStorage.removeItem('cooldownExpiresAt');
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const cooldownExpiresAt = localStorage.getItem('cooldownExpiresAt');
    if (cooldownExpiresAt && new Date(cooldownExpiresAt) > new Date()) {
      const duration = new Date(cooldownExpiresAt).getTime() - Date.now();
      startCooldown(duration);
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'cooldownExpiresAt' && event.newValue === null) {
        setIsCooldownActive(false);
        setCountdown(0);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearCooldownInterval();
    };
  }, []);

  return (
    <CooldownContext.Provider value={{ isCooldownActive, countdown, startCooldown: initiateCooldown }}>
      {children}
    </CooldownContext.Provider>
  );
};
