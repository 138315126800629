"use client";
import React, { createContext, useContext } from "react";

interface ScoresContextType {
  gameCount: number;
  pickCount: number;
}

const ScoresContext = createContext<ScoresContextType>({
  gameCount: 0,
  pickCount: 0,
});

export const useScores = () => {
  const context = useContext(ScoresContext);
  if (!context) {
    throw new Error("useScores must be used within a ScoresProvider");
  }
  return context;
};

export function ScoresProvider({ children }: {children: React.ReactNode}) {
  const [gameCount, setGameCount] = React.useState<number>(0);
  const [pickCount, setPickCount] = React.useState<number>(0);


  return (
    <ScoresContext.Provider value={{ gameCount, pickCount }}>
      {children}
    </ScoresContext.Provider>
  );
};
